import React from 'react'

import waltz_roll_jagoda from '../images/products/waltz-roll-jagoda.jpg'
import waltz_roll_cokolada from '../images/products/waltz-roll-cokolada.jpg'
import waltz_roll_ljesnjak from '../images/products/waltz-roll-ljesnjak.jpg'
import waltz_roll_kokos from '../images/products/waltz-roll-kokos.jpg'
import waltz_roll_mlecni from '../images/products/waltz-roll-mlecni.jpg'

class WaltzRoll extends React.Component{
    render(){
        return(
            <section id="proizvodi" className="hero products-page default-page">
                <div className="container padding-0 margin-0">
                    <div className="columns margin-0 main">
                        <div className="column is-4 static-part is-left padding-0">
                            <div className="content">
                                <div className="title">WALTZ ROLL</div>
                                <span className="zigzag"></span>
                                <p className="text">Rolat nikada nije bio tako ukusan kao Waltz roll okrugli 
                                sveži užitak, umotan u okruglu čaroliju kojom podsjećamo najmilije na ljubav
                                 i pažnju. Poklonite je nekome.</p>
                            </div>
                        </div>{/*STATIC PART END */}
                        <div className="column is-8 content-part is-right padding-0">
                            <div className="columns is-multiline inner margin-0">
                                <div className="column is-6 single-product padding-0">
                                    <img src={waltz_roll_jagoda} alt="Waltz roll jagoda" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={waltz_roll_cokolada} alt="Waltz roll čokolada"  />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={waltz_roll_ljesnjak} alt="Waltz roll kokos" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={waltz_roll_mlecni} alt="Waltz roll lješnjak"  />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={waltz_roll_kokos} alt="Waltz roll mlečni" />
                                </div>
                            </div>
                        </div>{/* PRODUCTS PART END */}
                    </div>{/* COLUMNS END */}
                </div>{/* CONTAINER END */}
            </section>/* PRODUCTS PAGE SECTION END */
        );
    }
}

export default WaltzRoll
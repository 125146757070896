import React from 'react'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Footer extends React.Component{
    render(){
        return(
            <section id="footer" className="hero footer">
                <div className="container is-fluid">
                    <div className="columns margin-0">
                        <div className="column is-4 navbar is-left padding-0">
                            <ul className="is-left">
                                <li><Link to="/o-nama">IMPRESUM</Link></li>
                                <li><Link to="/kontakt">KONTAKT</Link></li>
                            </ul>
                        </div>{/*LEFT NAVBAR END */}
                        <div className="column is-4 copyright is-center padding-0">
                            <p>© ĆATIĆ COMPANY</p>
                            <a href="https://digitala.ba/">Created by Digitala</a>
                        </div>{/* COPYRIGHT END */}
                        <div className="column is-4 navbar navbar-social is-right padding-0">
                            <ul className="is-right">
                                <li><a target="_blank" href="https://www.facebook.com/waltz.rs/"><FontAwesomeIcon icon={['fab', 'facebook-f']} aria-hidden="true" /></a></li>
                                <li><a target="_blank" href="https://www.instagram.com/waltz.rs/?hl=hr"><FontAwesomeIcon icon={['fab', 'instagram']} aria-hidden="true" /></a></li>
                            </ul>
                        </div>{/*LEFT NAVBAR END */}
                    </div>{/* COLUMNS END */}
                </div>{/*CONTAINER END */}
            </section>/*SECTION FOOTER END*/
        );
    }
}

export default Footer
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faArrowLeft, faLocationArrow, faArrowRight, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import '../styles/index.scss'
library.add( faFacebookF, faInstagram, faArrowLeft, faLocationArrow, faArrowRight, faBars, faTimes )

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>     
          {children}
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
